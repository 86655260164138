import './Cover.css';
import Navigator from "./Navigator/Navigator";

const Cover = ({ aboutScroll, contactScroll, prodactScroll, width }) => {
    return (
        <section className="cover">
            <div href="https://stampsy.com/na-elektrichkakh-do-baikala" className="cover__link">
                {width > 798 && <Navigator aboutScroll={aboutScroll} contactScroll={contactScroll} prodactScroll={prodactScroll} />}

                <h2 className="cover__title">Союз Дизель - Выбирай качество!</h2>
                <p className="cover__subtitle">Надежный партнер в сельскохозяйственной сфере!</p>
            </div>
        </section>
    )
}

export default Cover;