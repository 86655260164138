import doc from '../../images/svidetelstvo.png'
import './Documents.css';
const Documents = () => {
    return (
        <section className="documents">
            {/* <div className="documents-imgs"> */}
            <img className="documents-imgs" src={doc} alt="Свидетельство" />
            {/* </div> */}
        </section>
    )
}

export default Documents;