import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import './popupnav.css';

function PopupNav({ isOpen, onClose, aboutScroll, contactScroll, prodactScroll, }) {
    const location = useLocation();
    const navigate = useNavigate();
    const aboutScrollAndRef = (e) => {
        const mainPage = location.pathname !== '/';
        switch (e.target.attributes.id.value) {
            case 'popapAbout':
                mainPage ? navigate('/#about') : aboutScroll();
                break;
            case 'popapProducts':
                mainPage ? navigate('/#product') : prodactScroll();
                break;
            case 'popapContacts':
                mainPage ? navigate('/#contact') : contactScroll();
                break;
            default:
                break;
        }
        onClose()
    }

    return (
        <section className={`popup ${isOpen && "popup-opened"}`}>
            <div className="popup__form">
                <button className="popup__close" type="button" aria-label="close popup" onClick={onClose}></button>
                <div className="popup__conteiner">
                    <a to='/aboutTheCompany' id='popapAbout' className="popup__link" onClick={e => aboutScrollAndRef(e)}>О компании</a>
                    <a to="/products" id='popapProducts' className="popup__link" onClick={e => aboutScrollAndRef(e)}>Продукция</a>
                    <a href="/documents" id='popapDocuments' className="popup__link" onClick={onClose}>Документы</a>
                    <a href="https://hh.ru/vacancy/84372351?from=share_ios" className="popup__link" onClick={onClose} target="_blank">Карьера</a>
                    <a to="/contacts" id='popapContacts' className="popup__link" onClick={e => aboutScrollAndRef(e)}>Контакты</a>
                </div>
            </div>
        </section>
    );
}

export default PopupNav;