import React, { useState, useEffect, useRef } from 'react'
import './App.css';
import { Route, Routes, useLocation } from 'react-router-dom';
import Contact from '../Contact/Contact';
import Product from '../Product/Product';
import Footer from '../Footer/Footer';
import About from '../About/About';
import Cover from '../Cover/Cover';
import Header from '../Header/Header';
import Documents from '../Documents/Documents';
import Navigator from '../Cover/Navigator/Navigator';

function App() {
  const location = useLocation();

  const aboutRef = useRef(null);
  const productRef = useRef(null);
  const contactRef = useRef(null);
  const scrollToMyRef = (myRef) => {
    console.log(myRef.current);
    myRef.current.scrollIntoView({ behavior: 'smooth' });
  }

  const [width, setWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = (event) => {
      setWidth(event.target.innerWidth);
    };
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
    const href = window.location.href.substring(
      window.location.href.lastIndexOf('#') + 1,
    );
    if (window.location.href.lastIndexOf('#') > 0) {
      document.getElementById(href)?.scrollIntoView();
    }
  }, [location.pathname]);

  return (
    <div className="page">
      <Header aboutScroll={() => scrollToMyRef(aboutRef)}
        contactScroll={() => scrollToMyRef(contactRef)}
        prodactScroll={() => scrollToMyRef(productRef)} />
      <Routes>
        <Route path={`/`} element={
          <>
            <main>
              <Cover
                width={width}
                aboutScroll={() => scrollToMyRef(aboutRef)}
                contactScroll={() => scrollToMyRef(contactRef)}
                prodactScroll={() => scrollToMyRef(productRef)}
              />
              <About ref={aboutRef} />
              <Product ref={productRef} />
              <Contact ref={contactRef} />
            </main>
          </>
        } />

        <Route path={`/documents`} element={
          <>
            {width > 797 && <Navigator locDocuments={true} />}
            <Documents />
          </>} />

      </Routes>

      <Footer />
    </div>
  );
}

export default App;
