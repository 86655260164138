import React from 'react';
import './Navigator.css';
import { useLocation, useNavigate } from 'react-router-dom';

const Navigator = ({ aboutScroll, contactScroll, prodactScroll, locDocuments }) => {

    const location = useLocation();
    const navigate = useNavigate();

    const aboutScrollAndRef = (e) => {
        const mainPage = location.pathname !== '/';
        switch (e.target.attributes.id.value) {
            case 'navButAbout':
                mainPage ? navigate('/#about') : aboutScroll();
                break;
            case 'navButProduct':
                mainPage ? navigate('/#product') : prodactScroll();
                break;
            case 'navButContact':
                mainPage ? navigate('/#contact') : contactScroll();
                break;
            default:
                break;
        }
    }

    return (
        <div className={`navigator ${locDocuments ? 'navigator-small' : ''}`}>
            <ul className="navigator-button__ul">
                <li className="navigator-button__li">
                    <button id="navButAbout" onClick={e => aboutScrollAndRef(e)} className="navigator-button__ul-link">О компании</button>
                </li>
                <li>
                    <button id='navButProduct' href="#" onClick={e => aboutScrollAndRef(e)} className="navigator-button__ul-link">Продукция</button>
                </li>
                <li>
                    <a href="/documents" className="navigator-button__ul-link">Документы</a>
                </li>
                <li>
                    <a href="https://hh.ru/vacancy/84372351?from=share_ios" className="navigator-button__ul-link"
                        target="_blank">Карьера</a>
                </li>
                <li>
                    <button id='navButContact' href="#" onClick={e => aboutScrollAndRef(e)} className="navigator-button__ul-link">Контакты</button>
                </li>
            </ul>
        </div>
    )
}

export default Navigator;